import React, { PropsWithChildren } from "react";

import { Theme, makeStyles } from "@material-ui/core/styles";
import ibitzLogo from "../material/Icon/i-bitz.png";
import SILABLogo from "../material/Icon/SILAB.png";
import { Typography } from "@material-ui/core";
const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: "calc(100% - 2rem)",
    width: " calc(100% - 2rem)",
    padding: "1rem",
  },
  Active: {
    boxShadow: `0 0 8px #03252d, 0 0 13px #03252d, 0 0 35px #00846f, 0 0 25px #00846f`,
  },
}));
const ClusterComp = (props: PropsWithChildren<any>) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <Typography variant="body2" component="p">
        Power By
      </Typography>
      <div
        style={{
          marginTop: "-1rem",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open("https://i-bitz.co.th/", "_blank");
          }}
        >
          <img src={ibitzLogo} alt="Logo" width={50} />
          <Typography variant="body2" component="p">
            I-bitz company limited
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open("https://i-bitz.co.th/", "_blank");
          }}
        >
          <img src={SILABLogo} alt="Logo" width={50} />
          <Typography variant="body2" component="p">
            Spatial innovation lab
          </Typography>
        </div>

        {/* <Typography variant="h4" component="p">
          Plasmacluster
        </Typography> */}
      </div>
    </div>
  );
};

export default ClusterComp;
