import React, { createContext, useReducer } from "react";
import EN from "../constant/lang/en";
import TH from "../constant/lang/th";

interface ILang {
  locale: string;
  data: typeof EN | any;
}

const initalState: ILang = {
  locale: "th",
  data: TH,
};

type Action = { type: string; locale: string };

const reducer = (state: ILang, action: Action) => {
  switch (action.type) {
    case "CHANG_LANGUAGE":
      return { locale: action.locale, data: action.locale === "en" ? EN : TH };
    default:
      return state;
  }
};

export const changeLang = (lang: "en" | "th") => {
  return { type: "CHANG_LANGUAGE", locale: lang };
};

export const LangContext = createContext<{
  lang: ILang;
  dispatchLang: (action: Action) => void;
}>({ lang: initalState, dispatchLang: () => {} });

export const LangContextProvider = (props: any) => {
  const [lang, dispatchLang] = useReducer(reducer, initalState);
  return (
    <LangContext.Provider value={{ lang, dispatchLang }}>
      {props.children}
    </LangContext.Provider>
  );
};
export const LangContextConsumer = LangContext.Consumer;
