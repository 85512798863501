import { Grid, Typography } from "@material-ui/core";
import React, { PropsWithChildren, useContext } from "react";
import { LangContext } from "../context/lang";
import { Theme, makeStyles } from "@material-ui/core/styles";
import LogoLife from "../material/Image/Logo-Life-1.png";
const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: "calc(100% - 2rem)",
    width: " calc(100% - 2rem)",
    padding: "1rem",
  },
  grid: { height: "100%", width: "100%" },
}));
const HeaderComp = (props: PropsWithChildren<any>) => {
  const classes = useStyle();
  const { lang } = useContext(LangContext);
  return (
    <div className={classes.root}>
      <Grid container spacing={0} className={classes.grid}>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={LogoLife}
            alt="LogoLife"
            width={125}
            height={125}
            style={{
              padding: 8,
              borderRadius: "1rem",
              backgroundColor: "white",
            }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="p">
            {lang.data.announcement}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeaderComp;
