import React, {
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { LangContext } from "../context/lang";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { Fab, Typography } from "@material-ui/core";

import moment from "moment";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
moment.locale("th");

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: "calc(100% - 2rem)",
    width: " calc(100% - 2rem)",
    padding: "1rem",
  },
  fab: {
    textTransform: "unset",
    background: "linear-gradient(90deg, #3B8686 30%, #79BD9A 90%)",
  },
  fabUnselect: {
    textTransform: "unset",
    color: "#0B486B",
  },
  lineDiv: { height: "100%" },
}));
const GraphComp = (props: PropsWithChildren<any>) => {
  const classes = useStyle();
  const { lang } = useContext(LangContext);
  const [selectData, setselectData] = useState<any>("Active_Power_Total");

  const { metermultiData, lineChartRef } = props;

  const listData = [
    {
      path: "values.Power.Active_Power_Total",
      value: "Active_Power_Total",
      label: lang.data.Load,
      unit: "kW",
    },
    {
      path: "values.Active_Energy.Delivered",
      value: "Delivered",
      label: lang.data.Electricunit,
      unit: "Wh",
    },
    {
      path: "values.Power_Factor.Power_Factor_Total",
      value: "Power_Factor_Total",
      label: "Power Factor",
      unit: "",
    },
    {
      path: `values.Voltage["Voltage_L-L_Avg"]`,
      value: "Voltage_L-L_Avg",
      label: "Voltage LL",
      unit: "V",
    },
    {
      path: `values.Voltage["Voltage_L-N_Avg"]`,
      value: "Voltage_L-N_Avg",
      label: "Voltage LN",
      unit: "V",
    },
  ];

  const getValFromPath = (project: any, path: any) => {
    return path.split(".").reduce((res: any, prop: any) => res[prop], project);
  };
  const initData = useCallback(
    async (data: any, select: any) => {
      const getPath = listData.find((rs: any) => rs.value === select);

      const redata = data.data.map((rs: any) => {
        //console.log(rs);
        const value = getValFromPath(rs.data, getPath?.path);
        return {
          date: rs.meta._ts * 1000,
          unit: getPath?.unit,
          value: value,
        };
      });

      am4core.createFromConfig(
        {
          cursor: {
            type: "XYCursor",
          },
          xAxes: [
            {
              type: "DateAxis",
              dateFormatter: {
                dateFormat: "d MMM yyyy",
              },
              renderer: {
                grid: {
                  location: 0,
                  stroke: "#fff",
                },
                fontSize: 12,
                labels: {
                  template: {
                    fill: "#fff",
                    fontWeight: "bold",
                  },
                },
              },
            },
          ],
          yAxes: [
            {
              type: "ValueAxis",
              renderer: {
                grid: {
                  location: 0,
                  stroke: "#fff",
                },
                fontSize: 12,
                labels: {
                  template: {
                    fill: "#fff",
                    fontWeight: "bold",
                  },
                },
              },
            },
          ],
          series: [
            {
              type: "LineSeries",
              name: "TEST",
              dataFields: {
                valueY: "value",
                dateX: "date",
              },
              tooltipText: `[white]{dateX} : [bold&white]{valueY} ${
                redata[0] ? redata[0].unit : ""
              }`,
              tooltip: {
                background: {
                  fill: "#3B8686",
                  strokeWidth: 0,
                },

                getFillFromObject: false,
              },
              hoverOnFocus: true,
              strokeWidth: 2,
              // stroke: am4core.color(theme.palette.primary.main),
              fillOpacity: 0.25,
              // fill: am4core.color(theme.palette.primary.main),
              data: redata,
            },
          ],
        },
        lineChartRef ? lineChartRef.current : "",
        am4charts.XYChart
      );
    },
    [listData]
  );

  useEffect(() => {
    initData(metermultiData, selectData);
    return () => {};
  }, [initData, metermultiData, selectData]);

  const DataComp = (
    <div
      style={{
        display: "flex",

        margin: "1rem 0",
      }}
    >
      {listData.map((rs: any) => {
        return (
          <Fab
            key={rs.path}
            variant="extended"
            color="inherit"
            className={
              selectData === rs.value ? classes.fab : classes.fabUnselect
            }
            style={{ marginRight: "0.5rem" }}
            onClick={() => {
              setselectData(rs.value);
            }}
          >
            <Typography variant="h5" component="p">
              {rs.label}
            </Typography>
          </Fab>
        );
      })}
    </div>
  );

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",

          alignItems: "center",
        }}
      >
        {/* <Fab
          variant="extended"
          color="inherit"
          className={classes.fab}
          style={{ marginRight: "0.5rem" }}
          onClick={handleClick}
        >
          <Typography variant="h5" component="p" style={{ padding: "1rem" }}>
            {selectRange}
          </Typography>
          <MdExpandMore size={40} />
        </Fab>
        {SelectComp} */}

        {DataComp}
      </div>
      <div style={{ height: "85%" }}>
        <div ref={lineChartRef} className={classes.lineDiv} />
      </div>
    </div>
  );
};

export default GraphComp;
