import React, { createContext, useState } from "react";
import { Theme, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

interface IThemeContext {
  theme: Theme;
  settheme: Function;
}

const _theme_ = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat-Regular", "Kanit"].join(","),
  },
  palette: {
    //386573
    type: "dark",
    primary: { main: "#3a66ab" },
    secondary: { main: "#f5b827" },
    background: { paper: "#386472" },
  },
});

const initalState: IThemeContext = {
  theme: _theme_,
  settheme: () => {},
};

export const ThemeContext = createContext<IThemeContext>(initalState);
export const ThemeContextProvider = (props: any) => {
  const [theme, settheme] = useState<Theme>(_theme_);
  return (
    <ThemeContext.Provider value={{ theme, settheme }}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
export const ThemeContextConsumer = ThemeContext.Consumer;
