export default {
  // menu layout
  dashboard: "แดชบอร์ด",
  start: "เริ่มใช้งาน",

  //Announcement
  announcement:
    "อุทยานวิทยาศาสตร์เป็นโครงสร้างพื้นฐานที่สำคัญในการขับเคลื่อนการพัฒนาประเทศ ส่งเสริมการวิจัยและพัฒนา ตลอดจนบ่มเพาะผู้ประกอบการและเทคโนโลยีใหม่ๆ เข้าสู่ระบบเศรษฐกิจ",

  //Weather
  area: "พื้นที่",
  floor: "ชั้น",
  place: "ขอนแก่น, ขอนแก่น",
  CO2: "CO2",
  UV: "รังสี UV",
  PM25: "PM2.5",
  Humidity: " ความชื้น",
  Temperature: "อุณหภูมิ",
  Load: "โหลด",
  Electricunit: "หน่วยไฟฟ้า",
};
