import React from "react";
import ReactDOM from "react-dom";
import * as am4core from "@amcharts/amcharts4/core";
import { SnackbarProvider } from "notistack";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { LangContextProvider } from "./context/lang";
import { ThemeContextProvider } from "./context/theme";

am4core.addLicense("CH212555597");

ReactDOM.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <SnackbarProvider>
        <LangContextProvider>
          <App />
        </LangContextProvider>
      </SnackbarProvider>
    </ThemeContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
