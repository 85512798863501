import {
  CardHeader,
  Typography,
  CardContent,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Fab,
  Button,
} from "@material-ui/core";
import React, {
  Fragment,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { LangContext } from "../context/lang";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: "calc(100% - 2rem)",
    width: " calc(100% - 2rem)",
    padding: "1rem",
  },
  grid: { height: "100%", width: "100%" },
  SelectStyle: {
    "& .MuiInput-underline:before": {
      borderBottom: "unset",
      content: "unset",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "unset",
      content: "unset",
    },
  },
  fab: {
    textTransform: "unset",
    background: "linear-gradient(90deg, #3B8686 30%, #79BD9A 90%)",
  },
  fabUnselect: {
    textTransform: "unset",
    color: "#0B486B",
  },
}));
const BuildingComp = (props: PropsWithChildren<any>) => {
  const classes = useStyle();
  const { lang } = useContext(LangContext);
  const [type, settype] = useState("po");
  const {
    Area,

    meterList,
    buildingData,
    selectmeter,

    LoadNewData,
  } = props;

  const CheckForplan = (name: any, fl: any, meter: any) => {
    try {
      const img = require(`../material/Image/floorplan/${name}-${fl}-${
        type === "po" ? meter : `${meter}_Area`
      }.jpg`);

      return img;
    } catch (error) {
      return require(`../material/Image/floorplan/floor-${fl}-error.jpg`);
    }
  };

  return Area && buildingData && meterList && selectmeter ? (
    <Fragment>
      <CardHeader
        title={
          <Fragment>
            <FormControl classes={{ root: classes.SelectStyle }}>
              <Select
                style={{ fontFamily: "Kanit" }}
                value={Area.name}
                onChange={(e: any) => {
                  const newselet = buildingData.buildinglist.find(
                    (rs: any) => rs.name === e.target.value
                  );

                  newselet.floor = 1;

                  LoadNewData(newselet, null);
                }}
                startAdornment={
                  <Typography
                    variant="h5"
                    component="p"
                    style={{ paddingRight: "1rem" }}
                  >
                    {lang.data.area}
                  </Typography>
                }
              >
                {buildingData.buildinglist.map((st: any) => (
                  <MenuItem key={st.name} value={st.name}>
                    <Typography variant="h5" component="p">
                      {st.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl classes={{ root: classes.SelectStyle }}>
              <Select
                style={{ fontFamily: "Kanit" }}
                value={Area.floor}
                onChange={(e: any) => {
                  const newfloor = buildingData.buildinglist.find(
                    (rs: any) => rs.name === Area.name
                  );
                  newfloor.floor = e.target.value;
                  LoadNewData(newfloor, null);
                }}
                startAdornment={
                  <Typography
                    variant="h5"
                    component="p"
                    style={{ paddingRight: "1rem" }}
                  >
                    {lang.data.floor}
                  </Typography>
                }
              >
                {Area.floorArray.map((st: any) => (
                  <MenuItem key={st} value={st}>
                    <Typography variant="h5" component="p">
                      {st}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Fragment>
        }
        subheader={
          <Divider
            style={{
              backgroundColor: "#002c46",
              marginTop: "0.5rem",
              height: "5px",
              borderRadius: "1rem",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          />
        }
      />
      <CardContent
        style={{
          height: "calc(100% - 118px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          padding: "0 2rem",
        }}
      >
        <div>
          {meterList.map((rs: any) => {
            return (
              <Fab
                key={rs.code}
                variant="extended"
                color="inherit"
                className={
                  selectmeter.code === rs.code
                    ? classes.fab
                    : classes.fabUnselect
                }
                style={{ marginRight: "0.5rem" }}
                onClick={() => {
                  LoadNewData(null, rs);
                }}
              >
                <Typography variant="h5" component="p">
                  {rs.code.split("_")[1] === "MDB-C"
                    ? "MDB-D"
                    : rs.code.split("_")[1]}
                </Typography>
              </Fab>
            );
          })}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              left: "-4.3rem",
              top: "4rem",
              transform: "rotate(-90deg)",
            }}
          >
            <Button
              variant={type === "fl" ? "contained" : "outlined"}
              color="secondary"
              style={{ background: type === "fl" ? "" : "#fff" }}
              onClick={() => {
                settype("fl");
              }}
            >
              พื้นที่
            </Button>
            <Button
              variant={type === "po" ? "contained" : "outlined"}
              style={{ background: type === "fl" ? "#fff" : "" }}
              color="secondary"
              onClick={() => {
                settype("po");
              }}
            >
              ตำเเหน่ง
            </Button>
          </div>
          <TransformWrapper>
            <TransformComponent>
              <img
                src={CheckForplan(Area.name, Area.floor, selectmeter.code)}
                alt=""
                style={{ height: "30rem", borderRadius: "1rem" }}
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      </CardContent>
    </Fragment>
  ) : null;
};

export default BuildingComp;
