import React, { Fragment, PropsWithChildren, useContext } from "react";
import { LangContext } from "../context/lang";
import { Theme, makeStyles } from "@material-ui/core/styles";
import ClusterLogo from "../material/Icon/cluster.png";
import {
  CardContent,
  CardHeader,
  Divider,
  Fab,
  Grid,
  Typography,
} from "@material-ui/core";
import { number } from "@amcharts/amcharts4/core";
const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: "calc(100% - 2rem)",
    width: " calc(100% - 2rem)",
    padding: "1rem",
  },
  grid: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const TemperatureComp = (props: PropsWithChildren<any>) => {
  const classes = useStyle();
  const { lang } = useContext(LangContext);
  const { meterData, Area } = props;

  const FormComp = (
    label: string,
    icon: any,
    value: number,
    unit: string,
    color: string
  ) => {
    return (
      <div className={classes.center}>
        <Typography variant="h5" component="p">
          {label}
        </Typography>
        <div
          style={{ textAlign: "center", display: "flex", alignItems: "center" }}
        >
          <Typography
            variant="h4"
            component="p"
            style={{ marginRight: "0.3rem" }}
          >
            {value}
          </Typography>
          <Typography
            variant="h5"
            style={{ height: unit ? "" : "32px" }}
            component="p"
          >
            {unit}
          </Typography>
        </div>
        <div
          style={{
            backgroundColor: color,
            height: "1.2rem",
            width: "80%",
            borderRadius: "3rem",
          }}
        ></div>
      </div>
    );
  };
  return (
    <Fragment>
      <CardHeader
        title={
          <div style={{ textAlign: "center" }}>
            <Typography
              variant="h5"
              component="p"
              style={{ marginTop: "0.6rem" }}
            >
              {`${Area.name} : ${Area.floor}`}
            </Typography>
          </div>
        }
        subheader={
          <Divider
            style={{
              backgroundColor: "#002c46",
              marginTop: "0.7rem",
              height: "5px",
              borderRadius: "1rem",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          />
        }
        style={{ paddingBottom: "unset" }}
      />
      <CardContent
        style={{ height: "calc(100% - 106px)", paddingTop: "unset" }}
      >
        <Grid container spacing={0} className={classes.grid}>
          <Grid item xs={6}>
            {FormComp(
              lang.data.Load,
              "",
              typeof meterData.values.Power.Active_Power_Total === "number"
                ? meterData.values.Power.Active_Power_Total.toFixed(2)
                : 0,
              "kW",
              `#3B8686`
            )}
          </Grid>
          <Grid item xs={6}>
            {FormComp(
              lang.data.Electricunit,
              "",
              Number(
                Number(meterData.values.Active_Energy.Delivered) / 1000000
              ),
              "mWh",
              `#79BD9A`
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider
              style={{
                backgroundColor: "#002c46",
                marginTop: "0.7rem",
                height: "5px",
                borderRadius: "1rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={0}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={4}>
                {FormComp(
                  "Power Factor",
                  "",
                  Number(
                    typeof meterData.values.Power_Factor.Power_Factor_Total ===
                      "number"
                      ? meterData.values.Power_Factor.Power_Factor_Total.toFixed(
                          2
                        )
                      : 0
                  ),
                  "",
                  `#79BD9A`
                )}
              </Grid>
              <Grid item xs={4}>
                {FormComp(
                  "Voltage LL",
                  "",
                  Number(
                    typeof meterData.values.Voltage["Voltage_L-L_Avg"] ===
                      "number"
                      ? meterData.values.Voltage["Voltage_L-L_Avg"].toFixed(2)
                      : 0
                  ),
                  "V",
                  `#A8DBA8`
                )}
              </Grid>
              <Grid item xs={4}>
                {FormComp(
                  "Voltage LN",
                  "",
                  Number(
                    typeof meterData.values.Voltage["Voltage_L-N_Avg"] ===
                      "number"
                      ? meterData.values.Voltage["Voltage_L-N_Avg"].toFixed(2)
                      : 0
                  ),
                  "V",
                  `#CFF09E`
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider
              style={{
                backgroundColor: "#002c46",
                marginTop: "0.7rem",
                height: "5px",
                borderRadius: "1rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default TemperatureComp;
