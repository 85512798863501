import { Divider, Grid, Typography } from "@material-ui/core";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useCallback,
  useState,
  Fragment,
} from "react";
import { changeLang, LangContext } from "../context/lang";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { getPM25, getWeather } from "../api/weather";
import moment from "moment";
import "moment/locale/th";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  EmoiconL1,
  EmoiconL2,
  EmoiconL3,
  EmoiconL4,
  EmoiconL5,
} from "../material/Icon";
import { getMeterData } from "../api/meter";

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: "calc(100% - 2rem)",
    width: " calc(100% - 2rem)",
    padding: "1rem",
  },
  grid: { width: "100%", height: "100%" },
}));
const WeatherComp = (props: PropsWithChildren<any>) => {
  const classes = useStyle();
  const { lang, dispatchLang } = useContext(LangContext);
  const [init, setinit] = useState<Boolean>(false);
  const [weatherData, setweatherData] = useState<any>({});
  const [Lang, setLang] = useState<string>("th");

  const initData = useCallback(async () => {
    const abortController = new AbortController();
    const Weather = await getWeather(abortController);

    // const PM25 = await getPM25(abortController);
    // const PM252 = await getMeterData(1,abortController)
    // console.log(PM252);
    if (Weather) {
      const { current, hourly } = Weather;
      const Hourly = [];
      for (let index = 0; index < 4; index++) {
        Hourly.push(hourly[index]);
      }

      const reData = {
        current: {
          ...current,
          // co2: PM25[0].data.data.iaqi.co.v,
          // pm25: PM25[0].data.data.iaqi.pm25.v,
        },
        hourly: Hourly,
      };

      setweatherData(reData);
    }
  }, []);

  useEffect(() => {
    if (!init) {
      initData();
      setinit(true);
    }
  }, [initData, init]);
  const handleChange = (event: any, newAlignment: any) => {
    setLang(newAlignment);

    dispatchLang(changeLang(newAlignment));

    if (newAlignment === "th") {
      moment.locale("th");
    } else {
      moment.locale("en");
    }
  };
  const ToggleLang = (
    <ToggleButtonGroup
      size="small"
      value={Lang}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="en">EN</ToggleButton>
      <ToggleButton value="th">TH</ToggleButton>
    </ToggleButtonGroup>
  );

  const EmoTicon = () => {
    const CheckValueColour = (value: number) => {
      if (value >= 0 && value <= 25) {
        return 5;
      } else if (value >= 26 && value <= 37) {
        return 4;
      } else if (value >= 38 && value <= 50) {
        return 3;
      } else if (value >= 51 && value <= 90) {
        return 2;
      } else if (value >= 91) {
        return 1;
      } else {
        return 0;
      }
    };
    return (
      <Fragment>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "1rem 0",
          }}
        >
          <EmoiconL1
            style={{
              fontSize:
                CheckValueColour(weatherData.current.pm25) === 1
                  ? "86px"
                  : "53px",
              fill:
                CheckValueColour(weatherData.current.pm25) === 1
                  ? "#ff4040"
                  : "#ff404033",
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <EmoiconL2
            style={{
              fontSize:
                CheckValueColour(weatherData.current.pm25) === 2
                  ? "86px"
                  : "53px",

              fill:
                CheckValueColour(weatherData.current.pm25) === 2
                  ? "#f5a742"
                  : "#f5a74233",
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <EmoiconL3
            style={{
              fontSize:
                CheckValueColour(weatherData.current.pm25) === 3
                  ? "86px"
                  : "53px",

              fill:
                CheckValueColour(weatherData.current.pm25) === 3
                  ? "#f5ec42"
                  : "#f5ec4233",
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <EmoiconL4
            style={{
              fontSize:
                CheckValueColour(weatherData.current.pm25) === 4
                  ? "86px"
                  : "53px",

              fill:
                CheckValueColour(weatherData.current.pm25) === 4
                  ? "#bff542"
                  : "#bff54233",
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <EmoiconL5
            style={{
              fontSize:
                CheckValueColour(weatherData.current.pm25) === 5
                  ? "86px"
                  : "53px",

              fill:
                CheckValueColour(weatherData.current.pm25) === 5
                  ? "#69f542"
                  : "#69f54233",
            }}
          />
        </Grid>
      </Fragment>
    );
  };

  const GasStatus = (
    <Fragment>
      <Grid
        item
        xs={6}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" component="p">
          {lang.data.UV}
        </Typography>
        <Typography variant="h6" component="p" style={{ marginTop: "-0.5rem" }}>
          {weatherData.current ? weatherData.current.uvi : 0}
        </Typography>
      </Grid>

      <Grid
        item
        xs={6}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" component="p">
          {lang.data.Humidity}
        </Typography>
        <Typography variant="h6" component="p" style={{ marginTop: "-0.5rem" }}>
          {weatherData.current ? weatherData.current.humidity : 0} %
        </Typography>
      </Grid>
    </Fragment>
  );
  return (
    <div className={classes.root}>
      <Grid container spacing={0} className={classes.grid}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {weatherData.current && (
              <Fragment>
                <img
                  src={`https://openweathermap.org/img/wn/${weatherData.current.weather[0].icon}@2x.png`}
                  alt=""
                  height={150}
                />
                <div
                  style={{
                    marginTop: "-2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingRight: "2rem",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="p"
                    style={{ color: "#efc65d" }}
                  >
                    {`${(
                      Number(weatherData.current.temp) - Number(272.15)
                    ).toFixed(2)} °C`}
                  </Typography>
                  <Typography variant="body1" component="p">
                    {weatherData.current.weather[0].description}
                  </Typography>
                </div>
              </Fragment>
            )}
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",

              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                top: "3rem",
                right: "4rem",
              }}
            >
              {ToggleLang}
            </div>
            <div>
              <Typography
                style={{ color: "#efc65d" }}
                variant="body1"
                component="p"
              >
                {lang.data.place}
              </Typography>
              <Typography variant="h6" component="p">
                {moment(moment.now()).format("dddd Do MMMM YYYY")}
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} style={{}}>
          <Grid container spacing={0}>
            {weatherData.hourly &&
              weatherData.hourly.map((rs: any) => {
                return (
                  <Grid
                    item
                    key={rs.dt}
                    xs={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={`https://openweathermap.org/img/wn/${rs.weather[0].icon}@2x.png`}
                      alt=""
                      height={80}
                    />
                    <Typography
                      variant="h6"
                      component="p"
                      style={{
                        marginTop: "-1rem",
                      }}
                    >
                      {moment(new Date(rs.dt * 1000)).format("HH:mm")}
                    </Typography>
                  </Grid>
                );
              })}
            {/* <Grid
              item
              xs={12}
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                backgroundColor: "white",
              }}
            >
              <Divider />
            </Grid> */}
            {/* ================================== */}

            {/* <Grid item xs={12}>
              <Grid
                container
                spacing={0}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                {weatherData.current && weatherData.current.pm25
                  ? EmoTicon()
                  : null}
              </Grid>
            </Grid> */}

            <Grid item xs={12} style={{}}>
              <Divider
                style={{
                  backgroundColor: "#002c46",
                  marginTop: "0.7rem",
                  height: "5px",
                  borderRadius: "1rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  marginBottom: "0.7rem",
                }}
              />
            </Grid>
            {weatherData.current && GasStatus}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default WeatherComp;
