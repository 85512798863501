import React, { useState, useEffect, useCallback, useRef } from "react";
// import clsx from "clsx";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { Card, Grid } from "@material-ui/core";

import HeaderComp from "../../component/headerComp";
import WeatherComp from "../../component/weatherComp";
import ClusterComp from "../../component/clusterComp";
import TemperatureComp from "../../component/temperatureComp";
import BuildingComp from "../../component/buildingComp";

import GraphComp from "../../component/graphComp";
import { getMeterData } from "../../api/meter";
import { getBuildingData } from "../../api/building";

const useStyle = makeStyles((theme: Theme) => ({
  root: { height: "100%", width: "100%" },
  bg: { background: "#0B486B" },
}));

const Dashboard = () => {
  const classes = useStyle();
  //selectbuilding
  const [Area, setArea] = useState<any>(undefined);
  //all building
  const [buildingData, setbuildingData] = useState<any>(undefined);
  //storagelist
  //meterlist
  const [meterList, setmeterList] = useState<any>(undefined);
  const [selectmeter, setselectmeter] = useState<any>(undefined);
  const [meterData, setmeterData] = useState<any>(undefined);
  const [metermultiData, setmetermultiData] = useState<any>(undefined);
  const lineChartRef = useRef<any>(null);
  const initData = useCallback(async () => {
    const abortController = new AbortController();
    const builidngData = await getBuildingData(1, abortController);

    if (builidngData && builidngData.length) {
      const restuctdata = {
        ...builidngData[builidngData.length - 1].data,
        buildinglist: builidngData[
          builidngData.length - 1
        ].data.buildinglist.map((bd: any) => {
          let floorArray: any[] = [];
          for (let index = 1; index <= bd.floor; index++) {
            floorArray.push(index);
          }

          return { ...bd, floorArray: floorArray };
        }),
      };

      const filterstroage = builidngData[
        builidngData.length - 1
      ].data.meterlist.filter(
        (fl: any) =>
          fl.place === restuctdata.buildinglist[0].name && fl.floor === 1
      );

      const getStorageJob = (storage: string) => {
        if (storage) {
          let res = storage.split(".");
          let data = { vo: res[0], storage: storage };
          return data;
        } else {
          let data = { vo: "", storage: "" };
          return data;
        }
      };
      setbuildingData(restuctdata);
      setArea({ ...restuctdata.buildinglist[0], floor: 1 });
      setmeterList(filterstroage);

      setselectmeter(filterstroage[0]);

      const storage = getStorageJob(filterstroage[0].storage);

      const ElectData = await getMeterData(
        storage.vo,
        storage.storage,
        100,
        abortController
      );

      if (ElectData.data) {
        setmeterData(ElectData.data[ElectData.data.length - 1].data);
        setmetermultiData(ElectData);
      }
    }

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (!Area) {
      initData();
    } else {
    }
  }, [Area, initData]);

  const updateData = useCallback(
    async (meter: any, abortController: any) => {
      const getStorageJob = (storage: string) => {
        if (storage) {
          let res = storage.split(".");
          let data = { vo: res[0], storage: storage };
          return data;
        } else {
          let data = { vo: "", storage: "" };
          return data;
        }
      };

      const storage = getStorageJob(meter.storage);

      const ElectData = await getMeterData(
        storage.vo,
        storage.storage,
        1,
        abortController
      );

      if (ElectData.data) {
        setmeterData(ElectData.data[ElectData.data.length - 1].data);
        const reStruc = metermultiData.data;

        const addData = {
          ...metermultiData,
          data: [...reStruc, ElectData.data[ElectData.data.length - 1]],
        };

        setmetermultiData(addData);
      }
    },
    [metermultiData]
  );

  useEffect(() => {
    const abortController = new AbortController();
    const autoUpdate = setInterval(() => {
      if (selectmeter) {
        updateData(selectmeter, abortController);
      }
    }, 60000);

    return () => {
      clearInterval(autoUpdate);
      abortController.abort();
    };
  }, [selectmeter, updateData]);

  const Getdata = useCallback(async (param: any) => {
    const getStorageJob = (storage: string) => {
      if (storage) {
        let res = storage.split(".");
        let data = { vo: res[0], storage: storage };
        return data;
      } else {
        let data = { vo: "", storage: "" };
        return data;
      }
    };

    const Str = getStorageJob(param.storage);
    const abortController = new AbortController();

    const ElectData = await getMeterData(
      Str.vo,
      Str.storage,
      100,
      abortController
    );

    if (ElectData.data) {
      setmeterData(ElectData.data[ElectData.data.length - 1].data);
      setmetermultiData(ElectData);
    }
  }, []);

  const LoadNewData = useCallback(
    (area: any, meter: any) => {
      if (area) {
        setArea(area);

        const filterstorage = buildingData.meterlist.filter(
          (fl: any) => fl.place === area.name && fl.floor === area.floor
        );

        setmeterList(filterstorage);
        setselectmeter(filterstorage[0]);
        Getdata(filterstorage[0]);
      } else if (meter) {
        setselectmeter(meter);
        Getdata(meter);
      }
    },
    [buildingData, Getdata]
  );
  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={5} style={{ display: "flex", justifyContent: "center" }}>
        {/* ============= แยกสอง ============== */}
        <Grid container justify="center" spacing={3} className={classes.root}>
          <Grid item xs={12} style={{ height: "20vh" }}>
            <Card
              className={classes.bg}
              style={{
                height: "100%",
                width: "172%",
              }}
            >
              <HeaderComp />
            </Card>
          </Grid>
          <Grid item xs={12} style={{ height: "75vh" }}>
            <Card
              className={classes.bg}
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <BuildingComp
                Area={Area}
                buildingData={buildingData}
                setArea={setArea}
                meterList={meterList}
                selectmeter={selectmeter}
                LoadNewData={LoadNewData}
              />
            </Card>
          </Grid>
        </Grid>
        {/* ============= แยกสอง ============== */}
      </Grid>
      <Grid item xs={7} style={{ display: "flex", justifyContent: "center" }}>
        <Grid container justify="center" spacing={3} className={classes.root}>
          <Grid item xs={6} style={{ height: "20vh" }}></Grid>
          {/* ============ อากาศ =============== */}
          <Grid item xs={6} style={{ height: "20vh" }}>
            <Card
              className={classes.bg}
              style={{
                height: "240%",
                width: "100%",
              }}
            >
              <WeatherComp />
            </Card>
          </Grid>
          <Grid item xs={6} style={{ height: "25vh" }}>
            <Card
              className={classes.bg}
              style={{
                height: "165%",
                width: "100%",
              }}
            >
              {meterData && (
                <TemperatureComp meterData={meterData} Area={Area} />
              )}
            </Card>
          </Grid>
          <Grid item xs={6} style={{ height: "25vh" }}></Grid>
          <Grid item xs={6} style={{ height: "15vh" }}></Grid>
          <Grid item xs={6} style={{ height: "15vh" }}>
            <Card
              className={classes.bg}
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <ClusterComp />
            </Card>
          </Grid>
          <Grid item xs={12} style={{ height: "35vh" }}>
            <Card
              className={classes.bg}
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              {metermultiData && (
                <GraphComp
                  metermultiData={metermultiData}
                  lineChartRef={lineChartRef}
                />
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
