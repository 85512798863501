export const getWeather = (abortController?: AbortController) => {
  return fetch(
    `https://bs.jetstream.gmbh/v1.2/object/KKUSP.Weather/data?api_key=NmZhMWFhMDEtNjlhMi00ZDk1LThkNTMtYzVmNjZjNzg1MDNj`,
    {
      method: "GET",
      headers: {},
      signal: abortController?.signal,
    }
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => {
      console.log("error", error);
    });
};

export const getPM25 = (abortController?: AbortController) => {
  return fetch(
    `https://ap_demp.vekin.co.th/v1.2/storage/weather.aqi/objects?last=1`,
    {
      method: "GET",
      headers: {},
      signal: abortController?.signal,
    }
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => {
      console.log("error", error);
    });
};
