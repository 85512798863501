export const getBuildingData = (
  limit: number,
  abortController?: AbortController
) => {
  return fetch(
    `https://bs.jetstream.gmbh/v1.2/storage/KKUSP.meter_list/objects?last=${limit}`,
    {
      method: "GET",
      headers: {},
      signal: abortController?.signal,
    }
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => {
      console.log("error", error);
    });
};
