import React, { lazy, Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import "./App.css";
import Application from "./views/app";
import { ViewLoading } from "./views/loading";
import { makeStyles } from "@material-ui/styles";
import { ThemeContext } from "./context/theme";
const Authen = lazy(() => import("./views/auth"));
const Main = lazy(() => import("./views"));
const NotFound404 = lazy(() => import("./views/notfound404"));

const useStyle = makeStyles({
  "@global": {
    body: {
      margin: 0,
      padding: 0,
      // fontFamily: "Noto Sans,Noto Sans Thai"
    },
  },

  dark: {
    height: "100vh",
    width: "100vw",

    overflowY: "hidden",
    overflowX: "hidden",
    background: "linear-gradient(140deg, #002c46  30%, #161833 90%)",
  },
  light: {
    height: "100vh",
    width: "100vw",

    overflowY: "hidden",
    overflowX: "hidden",
    background: "#f2f2f2",
  },
});

interface IRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: IRouteProps) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        localStorage.getItem("token") ? (
          <Suspense fallback={<ViewLoading />}>
            <Component {...routeProps} />
          </Suspense>
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
            }}
          />
        )
      }
    />
  );
};

const SelfRoute = (props: IRouteProps) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        localStorage.getItem("token") ? (
          <Redirect
            to={{
              pathname: "/app",
            }}
          />
        ) : (
          <Suspense fallback={<ViewLoading />}>
            <Component {...routeProps} />
          </Suspense>
        )
      }
    />
  );
};

const PublicRoute = (props: IRouteProps) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <Suspense fallback={<ViewLoading />}>
          <Component {...routeProps} />
        </Suspense>
      )}
    />
  );
};

const App = () => {
  const classes = useStyle();
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className={theme.palette.type === "light" ? classes.light : classes.dark}
    >
      <Router>
        <Switch>
          <PrivateRoute path="/app" component={Application} />
          <SelfRoute path="/auth" component={Authen} />
          <PublicRoute path="/" exact component={Main} />
          <PublicRoute path="/404" component={NotFound404} />
          <Redirect to="/404" />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
