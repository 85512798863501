import React, { Fragment, PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
  rootForm: {
    padding: "2rem 2rem 0 2rem",
    height: "calc(100vh - 4rem)",
    display: "flex",
    justifyContent: "center",
  },
  font: {
    color: "#ef6d3b",
    fontSize: "0.8rem",
    fontWeight: 400,
    cursor: "pointer",
  },
}));

const AppLayout = (props: PropsWithChildren<any>) => {
  const classes = useStyle();

  return (
    <Fragment>
      <main className={classes.rootForm}>{props.children}</main>
      <div
        style={{
          padding: "0 3rem 0 3rem",
          display: "flex",
          justifyContent: "flex-end",

          alignItems: "center",
        }}
      >
        {/* <Typography
          variant="body1"
          component="p"
          color="textPrimary"
          style={{ marginRight: "0.5rem" }}
        >
          Powered by
        </Typography>
        <Typography
          variant="body1"
          className={classes.font}
          onClick={() => {
            window.open("https://www.jetstream.gmbh", "_blank");
          }}
        >
          JetStream
        </Typography> */}
      </div>
    </Fragment>
  );
};

export default AppLayout;
