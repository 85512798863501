export default {
  // menu layout
  dashboard: "Dashboard",
  start: "Start",

  //Announcement
  announcement:
    "Science Park is an important infrastructure for developing country. It supports research and development as well as incubates entrepreneurs and brings new technologies into economic system.",
  //Weather
  area: "Area",
  floor: "Floor",
  place: "Khonkaen, Khonkaen ",
  CO2: "CO2",
  UV: "UV",
  PM25: "PM2.5",
  Humidity: " Humidity",
  Temperature: "Temperature",
  Load: "Load",
  Electricunit: "Electric Unit",
};
