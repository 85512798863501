import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

import Dashboard from "./dashboard";

import { LinearProgress } from "@material-ui/core";

import AppLayout from "../../layout/appLayout";

export default () => {
  const match = useRouteMatch();

  return (
    <AppLayout>
      {localStorage.getItem("token") ? (
        <Switch>
          <Redirect
            exact
            from={`${match.url}/`}
            to={`${match.url}/dashboard`}
          />
          <Route path={`${match.url}/dashboard`} component={Dashboard} />
          <Redirect to="/404" />
        </Switch>
      ) : (
        <LinearProgress color="primary" />
      )}
    </AppLayout>
  );
};
