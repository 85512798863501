export const getMeterData = (
  vo: string,
  storage: string,

  limit: number,
  abortController?: AbortController
) => {
  return fetch(
    `https://api.jetstream.gmbh/v1.1/${vo}/storages/${storage}/objects?last=${limit}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "API-Key": `NmZhMWFhMDEtNjlhMi00ZDk1LThkNTMtYzVmNjZjNzg1MDNj`,
      },
      signal: abortController?.signal,
    }
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => {
      console.log("error", error);
    });
};
