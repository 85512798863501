import React from "react";
import clsx from "clsx";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Logo from "../material/Image/Logo-Life-1.png";
import { CircularProgress, LinearProgress } from "@material-ui/core";

const useStyle = makeStyles((theme: Theme) => ({
  viewBg: {
    height: "100vh",
    backgroundColor: "#E5EAEE",
  },
  middle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  center: { display: "flex", justifyContent: "center", position: "relative" },
  circleProcess: { position: "absolute", paddingRight: 0.5 },
  fixTop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
  },
  fullHeight: { height: "100%" },
}));

export const ViewLoading = () => {
  const classes = useStyle();
  return (
    <div className={clsx(classes.middle, classes.viewBg)}>
      <div className={classes.center}>
        <img
          src={Logo}
          alt="logo"
          width={125}
          height={125}
          style={{ padding: 8 }}
        />
        <CircularProgress
          className={classes.circleProcess}
          size={141}
          thickness={2}
          color="primary"
        />
      </div>
    </div>
  );
};

export const ContentLoading = () => {
  const classes = useStyle();
  return (
    <div className={classes.fixTop}>
      <LinearProgress />
    </div>
  );
};

export const CompoentLoading = () => {
  const classes = useStyle();
  return (
    <div className={clsx(classes.fullHeight, classes.middle)}>
      <div className={classes.center}>
        <CircularProgress color="primary" />
      </div>
    </div>
  );
};
